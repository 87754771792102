<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    width="600"
    :before-close="handleClose"
    :close-on-click-modal="false"
    class="main-dialog"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      v-if="dialogVisible"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="用户名：" prop="account">
        <el-input
          v-model="ruleForm.account"
          :disabled="ruleForm.userId ? true : false"
        ></el-input>
      </el-form-item>
      <el-form-item label="登录密码" prop="password" v-if="!ruleForm.userId">
        <div class="reset-password">
          <el-input
            v-model="ruleForm.password"
            :type="passwordType"
          >
            <template slot="append">
              <i
                class="el-icon-view"
                style="cursor: pointer"
                @click="
                  passwordType == 'text'
                    ? (passwordType = 'password')
                    : (passwordType = 'text')
                "
              ></i>
            </template>
          </el-input>
          <el-button
            style="margin-left: 14px"
            type="primary"
            size="mini"
            @click="ruleForm.password = 'Yili666+', passwordType = 'text'"
            >初始密码</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="昵称：" prop="name">
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phoneNum">
        <el-input v-model="ruleForm.phoneNum" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="头像" prop="avatar">
        <div style="display: flex; align-items: center">
          <el-image
            style="width: 48px; height: 48px; border-radius: 50%"
            :src="ruleForm.avatar"
            fit="cover"
          ></el-image>
          <el-button
            type="text"
            style="margin-left: 12px"
            @click="$refs.clickImage.click()"
            >更换头像</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="性别" prop="sex">
        <el-radio-group v-model="ruleForm.sex">
          <el-radio :label="true">男</el-radio>
          <el-radio :label="false">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="用户身份" prop="standingKey">
        <el-select
          v-model="ruleForm.standingKey"
          filterable
          placeholder="请选择"
          @change="handleChangestandingType"
        >
          <el-option
            :label="item.text"
            :value="item.value"
            v-for="(item, index) in userIdTypes"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户类型" prop="customerType">
        <el-select
          v-model="ruleForm.customerType"
          filterable
          remote
          @change="handleChangeCustomerType"
          placeholder="请选择"
        >
          <el-option
            :label="item.text"
            :value="item.value"
            v-for="(item, index) in userTypes"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户名称：" prop="realName">
        <el-input v-model="ruleForm.realName"></el-input>
      </el-form-item>
      <el-form-item label="证件类型" prop="cardType">
        <el-select
          v-model="ruleForm.cardType"
          filterable
          disabled
          remote
          placeholder="请选择"
        >
          <el-option
            :label="item.text"
            :value="item.value"
            v-for="(item, index) in cardTypes"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="证件号" prop="cardNo">
        <el-input
          v-model="ruleForm.cardNo"
          maxlength="18"
          @input="handlecardNoInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="所在城市" prop="regionArea">
        <v-distpicker
          ref="distpicker-box"
          @selected="onSelectedDist"
          @province="onSelectedDist($event, 'single')"
          @city="onSelectedDist($event, 'single', 'city')"
          :province="ruleForm.regionProvince"
          :city="ruleForm.regionCity"
          :area="ruleForm.regionArea"
        ></v-distpicker>
      </el-form-item>
      <!-- <el-form-item label="服务内容" prop="serviceContent">
        <el-input type="textarea" v-model="ruleForm.serviceContent"></el-input>
      </el-form-item> -->
      <el-form-item label="客户经理" prop="referrerName" required>
        <!-- <span>{{ ruleForm.referrerUserName || ruleForm.referrerName }} <span v-if="ruleForm.referrerUserId || ruleForm.referrerId">(用户ID：{{ ruleForm.referrerId || ruleForm.referrerId }})</span><span v-else>暂无推荐人</span></span> -->
        <el-select
          v-model="ruleForm.referrerName"
          filterable
          remote
          @change="handleChangeReferrer"
          placeholder="请选择"
        >
          <el-option
            :label="item.salesmanName"
            :value="item.salesmanName"
            v-for="(item, index) in referrerList"
            :key="index"
          ></el-option>
        </el-select>
        <el-button
          type="text"
          style="margin-left: 12px"
          @click="isAllocationCustomer = true"
          >更换</el-button
        >
      </el-form-item>
      <el-form-item
        label="业务代理人"
        prop="agentId"
        v-if="!this.$store.state.userInfo.myAgentConcat"
      >
        <!-- <span>{{ ruleForm.referrerUserName || ruleForm.referrerName }} <span v-if="ruleForm.referrerUserId || ruleForm.referrerId">(用户ID：{{ ruleForm.referrerId || ruleForm.referrerId }})</span><span v-else>暂无推荐人</span></span> @change="handleChangeReferrer" -->
        <el-select v-model="ruleForm.agentId" filterable placeholder="请选择">
          <el-option
            :label="item.realName"
            :value="item.uniqueId"
            v-for="(item, index) in agentList"
            :key="index"
          ></el-option>
        </el-select>
        <!-- <el-button type="text" style="margin-left: 12px" @click="isAllocationCustomer = true">更换</el-button> -->
      </el-form-item>
      <el-form-item label="证件照片" prop="cardFileUrl" required>
        <el-upload
          class="avatar-uploader"
          action="https://jsonplaceholder.typicode.com/posts/"
          :show-file-list="false"
          :auto-upload="false"
          :on-change="handlePreview"
          :on-remove="handleRemove"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="启用" prop="enable">
        <el-radio-group v-model="ruleForm.enable">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
        <el-button @click="resetForm('ruleForm')">重 置</el-button>
      </el-form-item>
    </el-form>
    <recommendDialog
      :recommendId="ruleForm.referrerUniqueId"
      @changeID="handleSetRuleForm"
      v-model="isAllocationCustomer"
    />
    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose" size="mini">确 定</el-button>
      <el-button @click="handleClose" size="mini">重 置</el-button>
    </span>-->
    <input
      type="file"
      name=""
      id=""
      ref="clickImage"
      style="width: 0; height: 0"
      accept="image/jpeg,image/jpg,image/png"
      @change="changeImage"
    />
  </el-dialog>
</template>

<script>
import {
  listTenant,
  customerUpdate,
  customerAdd,
  getOwnBusiness,
  getAgentBySalesmanId,
  getOwnBusinessArea,
} from "@/api/SystemData";
import { OSSDirectPass } from "@/api/oss.js";
import VDistpicker from "v-distpicker";
import recommendDialog from "@/views/SystemManagement/components/customer/recommendDialog.vue";
import { phoneFun, fileToBase64 } from "@/utils/index";
import { validatePassword, generatePassword } from "@/utils/auth";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    editData: {
      type: Object,
      default: () => {},
    },
    userTypes: {
      type: Array,
      default: () => [],
    },
  },
  components: { VDistpicker, recommendDialog },
  watch: {
    dialogVisible: {
      handler(v) {
        if (v && this.editData.userId) {
          this.ruleForm = JSON.parse(JSON.stringify(this.editData));
          this.ruleForm.cardFileUrl
            ? (this.imageUrl = this.ruleForm.cardFileUrl)
            : "";
          if (this.ruleForm.cardNo) {
            getOwnBusiness({
              cardNo: this.ruleForm.cardNo,
            }).then((res) => {
              this.referrerList = res.data;
            });
          }
          if (this.ruleForm.referrerUserId) {
            this.handlrGetAgent();
          } else {
            this.agentList = [];
          }
        } else if (!this.editData.userId) {
          this.imageUrl = "";
          this.imageFile = "";
          this.agentList = [];
          this.ruleForm = {
            avatar: "",
            customerType: "",
            customerTypeDesc: "",
            enable: true,
            account: "",
            realName: "",
            password: "",
            name: "",
            phoneNum: "",
            referrerUniqueId: "",
            referrerUserId: "",
            regionArea: "",
            regionCity: "",
            regionProvince: "",
            serviceContent: "",
            sex: true,
            userId: "",
            cardNo: "",
            agentId: "",
            referrerId: "",
            referrerName: "",
          };
          this.fileObj = null;
          this.$refs.clickImage && (this.$refs.clickImage.value = "");
        }
      },
      deep: true,
    },
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      if (!phoneFun(value) && value) {
        callback(new Error("请输入密码"));
      } else if (!value) {
        callback(new Error("请输入密码"));
      } else {
        // this.$refs.ruleForm.validateField('checkPass');
        callback();
      }
    };
    let formValidatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (!validatePassword(value) && value) {
        callback(new Error("请输入正确的密码"));
      } else {
        callback();
      }
    };
    return {
      // 客户经理根据什么带出，card:证件号，area：地区
      genCustomerMgTy: "area",
      genCustomerMgLv: {
        card: 2,
        area: 1,
      },
      ruleForm: {
        avatar: "",
        customerType: "",
        customerTypeDesc: "",
        enable: true,
        account: "",
        realName: "",
        agentId: "",
        password: "",
        name: "",
        phoneNum: "",
        referrerUniqueId: "",
        referrerUserId: "",
        referrerId: "",
        referrerName: "",
        regionArea: "",
        regionCity: "",
        regionProvince: "",
        serviceContent: "",
        sex: true,
        userId: "",
        cardNo: "",
        standingKey: "",
      },
      userIdTypes: [
        {
          text: "客户",
          value: 1,
        },
        {
          text: "保司",
          value: 2,
        },
        {
          text: "政府",
          value: 3,
        },
        {
          text: "协会",
          value: 4,
        },
      ],
      rules: {
        account: [{ required: true, message: "请输入昵称", trigger: "change" }],
        realName: [
          { required: true, message: "请输入客户名称", trigger: "change" },
        ],
        phoneNum: [
          {
            required: true,
            validator: validateMobile,
            message: "请输入正确的号码",
            trigger: "change",
          },
        ],
        customerType: [
          { required: true, message: "请选择用户类型", trigger: "change" },
        ],
        standingKey: [
          { required: true, message: "请选择用户身份", trigger: "change" },
        ],
        regionArea: [
          { required: true, message: "请选择注册区域", trigger: "change" },
        ],
        cardNo: [
          { required: true, message: "请输入证件号", trigger: "change" },
        ],
        referrerName: [
          {
            required: true,
            message: "请选择客户经理",
            trigger: ["change", "blur"],
          },
        ],
        cardFileUrl: [
          { required: true, message: "请上传证件照片", trigger: "change" },
        ],
        password: [
          { required: false, validator: formValidatePassword, trigger: "change" },
          { min: 8, max: 32, message: "密码最小长度为8位，密码由数字、字母和特殊字符组合", trigger: "change" },
        ],
        //   roleName: [
        //     { required: true, message: '请输入角色名称', trigger: 'change' },
        //     { min: 0, max: 32, message: '长度不能超过32个字符', trigger: 'change' }
        //   ],
        //   remark: [
        //     { min: 0, max: 128, message: '长度不能超过128个字符', trigger: 'change' }
        //   ],
      },
      loading: true,
      options: [],
      fileObj: null,
      recommendId: "",
      isAllocationCustomer: false,
      passwordType: "password",
      cardTypes: [
        { text: "身份证", value: 2 },
        { text: "统一社会信用代码", value: 1 },
      ],
      imageUrl: "",
      imageFile: "",
      referrerList: [],
      agentList: [],
      cardNoTime: null,
      generatePassword,
    };
  },
  async created() {
    // this.loading = true;
    // let list = await this.initList();
    // this.loading = false;
    // else {
    //   this.options = [];
    // }
  },
  methods: {
    initList(query = "") {
      return new Promise((resolve, reject) => {
        listTenant({
          tenantName: query,
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(false);
          });
      });
    },
    handleClose() {
      this.referrerList = [];
      this.genCustomerMgTy = "area";
      this.$emit("update:dialogVisible", false);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let obj = {};
          if (this.imageUrl && this.imageFile) {
            let ossUrl = await OSSDirectPass(this.imageFile);
            obj.cardFileUrl = ossUrl;
          }
          let customerData = this.userTypes.filter(
            (val) => val.value == this.ruleForm.customerType
          );
          if (customerData.length) {
            this.ruleForm.customerTypeDesc = customerData[0].text;
          }
          if (this.fileObj) {
            let ossUrl = await OSSDirectPass(this.fileObj);
            obj.avatar = ossUrl;
          }
          if (this.ruleForm.userId) {
            customerUpdate({ ...this.ruleForm, ...obj }).then((res) => {
              this.handleClose();
              this.$emit("changeList");
            });
          } else {
            customerAdd({ ...this.ruleForm, ...obj }).then((res) => {
              this.handleClose();
              this.$emit("changeList");
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(type) {
      if (this.editData.userId) {
        this.ruleForm = JSON.parse(JSON.stringify(this.editData));
      } else {
        this.ruleForm = {
          avatar: "",
          customerType: "",
          customerTypeDesc: "",
          enable: true,
          account: "",
          realName: "",
          agentId: "",
          password: "",
          name: "",
          phoneNum: "",
          referrerUniqueId: "",
          referrerUserId: "",
          regionArea: "",
          regionCity: "",
          regionProvince: "",
          serviceContent: "",
          sex: true,
          userId: "",
        };
        this.fileObj = null;
      }
    },
    async remoteMethod(query) {
      this.loading = true;
      let list = await this.initList(query);
      this.loading = false;
      if (list) {
        this.options = list;
      } else {
        this.options = [];
      }
    },
    onSelectedDist(e, type, areaType) {
      //根据市获取客户经理
      if (areaType === "city") {
        if (e.value && this.compareDataLv("area")) {
          getOwnBusinessArea({ city: e.value }).then((res) => {
            this.referrerList = res.data;
            if (this.referrerList.length) {
              this.handleChangeReferrer(this.referrerList[0].salesmanName);
            }
          });
        }
      }
      if (type == "single") {
        this.ruleForm.regionArea = "";
        return false;
      } else if (e.province) {
        this.ruleForm.regionProvince = e.province.value;
        this.ruleForm.regionCity = e.city.value;
        this.ruleForm.regionArea = e.area.value;
      }
    },
    changeImage(e) {
      var file = e.target.files[0];
      var reader = new FileReader();
      var that = this;
      reader.readAsDataURL(file);
      this.fileObj = { name: file.name, raw: file };
      console.log(this.fileObj, file);
      reader.onload = function (res) {
        that.ruleForm.avatar = this.result;
      };
    },
    handleSetRuleForm(e) {
      this.isAllocationCustomer = false;
      e.referrerName = e.referrerUserName;
      Object.assign(this.ruleForm, e);
      this.ruleForm.agentId = "";
      this.handlrGetAgent();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    async handlePreview(file) {
      this.imageFile = file;
      this.imageUrl = await fileToBase64(file.raw);
      this.ruleForm.cardFileUrl = this.imageUrl;
    },
    handleChangeCustomerType(e) {
      let data = this.userTypes.find((val) => val.value == e);
      this.ruleForm.cardType = data.text == "个体客户" ? 2 : 1;
      this.ruleForm.cardTypeDesc =
        data.text == "个体客户" ? "身份证" : "统一社会信用代码";
      this.customerTypeDesc = data.text;
    },
    handleChangeReferrer(e) {
      let data = this.referrerList.find((val) => val.salesmanName == e);
      console.log(data);
      this.ruleForm.referrerId = data.uniqueId;
      this.ruleForm.referrerName = data.salesmanName;
    },
    handlrGetAgent() {
      getAgentBySalesmanId({
        salesmanId: this.ruleForm.referrerUserId,
      })
        .then((res) => {
          this.agentList = res.data;
        })
        .catch((err) => {
          this.agentList = [];
        });
    },
    handlecardNoInput(e) {
      clearTimeout(this.cardNoTime);
      this.cardNoTime = null;
      if (e) {
        this.cardNoTime = setTimeout(() => {
          getOwnBusiness({
            cardNo: e,
          }).then((res) => {
            this.referrerList = res.data;
            if (this.referrerList.length && this.compareDataLv("card")) {
              this.handleChangeReferrer(this.referrerList[0].salesmanName);
            }
          });
          clearTimeout(this.cardNoTime);
          this.cardNoTime = null;
        }, 700);
      }
    },
    //  对比级别
    compareDataLv(lvtype = "") {
      if (!lvtype) return true;
      if (
        this.genCustomerMgLv[lvtype] &&
        this.genCustomerMgLv[lvtype] >=
          this.genCustomerMgLv[this.genCustomerMgTy]
      ) {
        this.genCustomerMgTy = lvtype;
        return true;
      }
      return false;
    },
    handleChangestandingType(e) {
      let res = this.userIdTypes.find((item) => {
        return item.value === e;
      });
      if (res) {
        this.ruleForm.standingValue = res.text;
      } else {
        this.ruleForm.standingValue = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4b7bca;
  .el-dialog__title {
    color: #fff;
  }
}
::v-deep .el-dialog__body {
  max-height: 600px;
  overflow: auto;
}
.reset-password {
  display: flex;
}
.avatar-uploader {
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  ::v-deep .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
