<template>
  <div class="container">
    <GeneralTable
      ref="GeneralTable"
      :dataList="claimRecordList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @search-change="getList"
      @add-change="
        (operateTitle = '添加'), (editData = {}), (isAddVisible = true)
      "
      @del-change="deleteItem"
      @export-data="exportData"
      tableName="CustomerManagement"
    >
      <template slot="operate-left">
        <el-button
          type="success"
          size="mini"
          v-if="handleHasPerms('M22-customer-edit')"
          @click="handleAddUser"
          >添加</el-button
        >
        <el-button
          type="primary"
          size="mini"
          v-if="handleHasPerms('M22-customer-edit')"
          @click="handleAllocationCustomer"
          >更换客户经理</el-button
        >
        <!-- <el-button
          type="primary"
          size="mini"
          v-if="handleHasPerms('M22-customer-edit')"
          @click="handleBindProject"
          >绑定项目</el-button
        > -->
      </template>
      <template slot="regionProvince">
        <v-distpicker
          class="hide-picker"
          ref="distpicker-box"
          :province="conditionData.regionProvince"
          :city="conditionData.regionCity"
          :area="conditionData.regionArea"
          @province="onSelectedSingle($event, 'regionProvince')"
          @city="onSelectedSingle($event, 'regionCity')"
          @area="onSelectedSingle($event, 'regionArea')"
        ></v-distpicker>
      </template>
      <template slot="avatar" slot-scope="{ item }">
        <el-image
          style="width: 32px; height: 32px; border-radius: 50%"
          :src="item.avatar"
          fit="cover"
        ></el-image>
      </template>
      <template slot="avatar" slot-scope="{ item }">
        <el-image
          style="width: 32px; height: 32px; border-radius: 50%"
          :src="item.avatar"
          fit="cover"
        ></el-image>
      </template>
      <template slot="avatar" slot-scope="{ item }">
        <el-image
          style="width: 32px; height: 32px; border-radius: 50%"
          :src="item.avatar"
          fit="cover"
        ></el-image>
      </template>
      <template slot="sex" slot-scope="{ item }">
        {{ item.sex ? "男" : item.sex === false ? "女" : "--" }}
      </template>
      <template slot="enable" slot-scope="{ item }">
        {{ item.enable ? "是" : item.enable === false ? "否" : "--" }}
      </template>
      <template slot="rzzt" slot-scope="{ item }">
        {{ item.rzzt ? "已认证" : item.rzzt === false ? "未认证" : "--" }}
      </template>
      <template slot="cardFileUrl" slot-scope="{ item }">
        <el-image
          style="width: 100px; height: 100px"
          :src="item.cardFileUrl"
          v-if="item.cardFileUrl"
          :preview-src-list="[item.cardFileUrl]"
        >
        </el-image>
        <span v-else>--</span>
      </template>
      <template slot="operate" slot-scope="{ item }">
        <el-button
          class="polecy-button"
          type="text"
          @click="handleResetPassword(item)"
          v-if="handleHasPerms('M22-customer-edit')"
          size="small"
          >重置密码</el-button
        >
        <el-button
          class="polecy-button"
          @click.native.prevent="editItem(item)"
          v-if="handleHasPerms('M22-customer-edit')"
          type="text"
          size="small"
          >编辑</el-button
        >
        <el-button
          class="polecy-button polecy-button-no"
          @click.native.prevent="deleteItem([item])"
          v-if="handleHasPerms('M22-customer-del')"
          type="text"
          size="small"
          >删除</el-button
        >
      </template>
    </GeneralTable>
    <el-dialog
      title="重置密码"
      :visible.sync="isByUserVisible"
      width="400"
      :close-on-click-modal="false"
      :before-close="() => (isByUserVisible = false)"
    >
      <div class="reset-password">
        <div style="width: 120px">新密码</div>
        <el-input v-model.trim="password" :type="passwordType" placeholder="最小长度为8位，密码由数字、字母和特殊字符组合">
          <template slot="append">
            <i
              class="el-icon-view"
              style="cursor: pointer"
              @click="
                passwordType == 'text'
                  ? (passwordType = 'password')
                  : (passwordType = 'text')
              "
            ></i>
          </template>
        </el-input>
        <el-button
          style="margin-left: 14px"
          type="primary"
          size="mini"
          @click="password = 'Yili666+', passwordType = 'text'"
          >初始密码</el-button
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isByUserVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleUpdateUser">确 定</el-button>
      </span>
    </el-dialog>
    <customer-add-dialog
      :dialogVisible.sync="isAddVisible"
      :title="operateTitle"
      :editData="editData"
      :userTypes="userTypes"
      @changeList="changeList"
    />
    <recommendDialog
      @changeID="handleSetRuleForm"
      v-model="isAllocationCustomer"
    />
    <projectDialog
      @submit="bindProject"
      :dialogVisible.sync="bindProjectOpen"
    ></projectDialog>
  </div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import {
  customerPage,
  customerDelete,
  customerExportExcel,
  getRelateTreeData,
  roleBindUser,
  listGrantedUser,
  customerBindReferrer,
  customerResetPwd,
} from "@/api/SystemData";
import { hasPerms, validatePassword, generatePassword } from "@/utils/auth";
import VDistpicker from "v-distpicker";
import { dictionaryBatch } from "@/api/policy";
import customerAddDialog from "@/views/SystemManagement/components/customer/customerAddDialog.vue";
import recommendDialog from "@/views/SystemManagement/components/customer/recommendDialog.vue";
import projectDialog from "@/views/SystemManagement/components/customer/projectDialog.vue";
import { bindUserProject } from "@/api/project.js";
export default {
  data() {
    return {
      option: {
        isAdd: false, //添加 事件 @add-change
        isDel: hasPerms("M22-customer-del"), //删除 @del-change
        isEdit: false, // 编辑 @edit-change
        isExport: hasPerms("M22-customer-export"),
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        rowPlaceholder: "--", // 列表数据为空时的显示
        searchList: [
          {
            label: "用户昵称",
            prop: "name",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入用户昵称",
          },
          {
            label: "手机号",
            prop: "phoneNum",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入手机号",
          },
          {
            label: "性别",
            prop: "sex",
            formType: "select",
            filterable: true,
            placeholder: "请选择类型",
            selectList: [
              { value: "", text: "全部" },
              { value: true, text: "男" },
              { value: false, text: "女" },
            ],
          },
          {
            label: "客户名称",
            prop: "realName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入客户名称",
          },
          {
            label: "客户类型",
            prop: "customerType",
            formType: "select",
            filterable: true,
            placeholder: "请选择类型",
            selectList: [],
          },
          {
            label: "所在城市",
            prop: "regionProvince",
            formType: "range-input",
            filterable: true,
            placeholder: "请选择类型",
          },
          {
            label: "证件号",
            prop: "cardNo",
            formType: "input",
            maxLength: 18,
            clearable: true,
            placeholder: "请输入证件号",
          },
          {
            label: "客户经理",
            prop: "referrerName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入客户经理",
            isPermissions: !this.$store.state.userInfo.myAgentConcat,
          },
          {
            label: "认证状态",
            prop: "rzzt",
            formType: "select",
            filterable: true,
            placeholder: "请选择状态",
            selectList: [
              { value: "", text: "全部" },
              { value: true, text: "已认证" },
              { value: false, text: "未认证" },
            ],
          },
          {
            label: "启用状态",
            prop: "enable",
            formType: "select",
            filterable: true,
            placeholder: "请选择状态",
            selectList: [
              { value: "", text: "全部" },
              { value: true, text: "是" },
              { value: false, text: "否" },
            ],
          },
          {
            label: "所属租户",
            prop: "referrerTenantName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            isPermissions: this.$store.state.tenantId === "000000",
            placeholder: "请输入所属租户",
          },
          {
            label: "创建时间",
            formType: "daterange",
            clearable: true,
            format: "yyyy.MM.dd",
            prop: ["createTimeBegin", "createTimeEnd"],
          },
          {
            label: "是否推荐",
            prop: "sftj",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            selectList: [
              { value: "", text: "全部" },
              { value: true, text: "是" },
              { value: false, text: "否" },
            ],
            isPermissions: !this.$store.state.userInfo.myAgentConcat,
          },
          {
            label: "用户身份",
            prop: "standingKey",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            selectList: [
              { value: "", text: "全部" },
              {
                text: "客户",
                value: 1,
              },
              {
                text: "保司",
                value: 2,
              },
              {
                text: "政府",
                value: 3,
              },
              {
                text: "协会",
                value: 4,
              },
            ],
            isPermissions: !this.$store.state.userInfo.myAgentConcat,
          },
          {
            label: "认证状态",
            prop: "rzzt",
            formType: "select",
            filterable: true,
            placeholder: "请选择",
            selectList: [
              { value: "", text: "全部" },
              { value: true, text: "是" },
              { value: false, text: "否" },
            ],
          },
          {
            label: "推荐人",
            prop: "agentName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "用户昵称",
          prop: "name",
          align: "center",
          isShow: true,
        },
        {
          label: "手机号",
          prop: "phoneNum",
          align: "center",
          isShow: true,
        },
        {
          label: "用户名",
          prop: "account",
          align: "center",
          isShow: true,
        },
        {
          label: "头像",
          prop: "avatar",
          align: "center",
          width: "100",
          isShow: true,
          isSlot: true,
        },
        {
          label: "性别",
          prop: "sex",
          align: "center",
          isShow: true,
          isSlot: true,
        },
        {
          label: "客户类型",
          prop: "customerTypeDesc",
          align: "center",
          isShow: true,
        },
        {
          label: "客户名称",
          prop: "realName",
          align: "center",
          isShow: true,
        },
        {
          label: "证件类型",
          prop: "cardTypeDesc",
          align: "center",
          isShow: true,
        },
        {
          label: "证件号",
          prop: "cardNo",
          align: "center",
          isShow: true,
        },
        {
          label: "所在城市",
          prop: "regionProvince",
          align: "center",
          isShow: true,
        },
        {
          label: "是否代理人推荐",
          prop: "sftjDesc",
          align: "center",
          isShow: true,
          isPermissions: !this.$store.state.userInfo.myAgentConcat,
        },
        {
          label: "代理人",
          prop: "agentName",
          align: "center",
          isShow: true,
        },
        {
          label: "客户经理",
          prop: "referrerName",
          align: "center",
          isShow: true,
          isPermissions: !this.$store.state.userInfo.myAgentConcat,
        },
        {
          label: "用户身份",
          prop: "standingValue",
          align: "center",
          isShow: true,
        },
        {
          label: "所属租户",
          prop: "referrerTenantName",
          align: "center",
          isPermissions: this.$store.state.tenantId === "000000",
          isShow: true,
        },
        {
          label: "是否启用",
          prop: "enable",
          align: "center",
          isShow: true,
          isSlot: true,
        },
        {
          label: "认证状态",
          prop: "rzzt",
          align: "center",
          isShow: true,
          isSlot: true,
        },
        {
          label: "证件照片",
          prop: "cardFileUrl",
          align: "center",
          isShow: true,
          isSlot: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          align: "center",
          isShow: true,
        },
        {
          label: "最后登录时间",
          prop: "lastLoginTime",
          align: "center",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          align: "center",
          width: 200,
          fixed: "right",
          isSlot: true,
        },
      ],
      //数据总量
      totalNum: 0,
      claimRecordList: [],
      operateTitle: "添加",
      isAddVisible: false,
      editData: {},
      roleId: "",
      showTreeDialog: false,
      relateTree: [],
      treeSelectKeyList: [],
      treeProps: {
        children: "children",
        label: "title",
        disabled: "selected",
        //员工标识
        type: 2,
        //标识名
        typeName: "type",
        uuidName: "id",
      },
      topPickerData: [],
      oldFormData: {},
      conditionData: {
        regionProvince: "",
        regionCity: "",
        regionArea: "",
      },
      userTypes: [],
      customerIds: [],
      isAllocationCustomer: false,
      bindProjectOpen: false,
      isByUserVisible: false,
      passwordType: "text",
      password: "",
      generatePassword,
    };
  },
  components: {
    GeneralTable,
    VDistpicker,
    customerAddDialog,
    recommendDialog,
    projectDialog,
  },
  created() {
    dictionaryBatch({
      codes: "customerType",
    }).then((res) => {
      if (res && res.data) {
        let ind = this.option.searchList.findIndex(
          (val) => val.label === "客户类型"
        );
        this.option.searchList[ind].selectList = res.data.customerType.map(
          (val) => {
            return {
              text: val.dictValue,
              value: val.dictKey,
            };
          }
        );
        this.userTypes = res.data.customerType.map((val) => {
          return {
            text: val.dictValue,
            value: val.dictKey,
          };
        });
        this.option.searchList[ind].selectList.unshift({
          text: "全部",
          value: "",
        });
      }
    });
  },
  watch: {},
  methods: {
    //获取数据
    async getList(v, pageNum, pageSize, searcType, isReset) {
      if (isReset == "reset") {
        this.conditionData.regionProvince = "";
        this.conditionData.regionCity = "";
        this.conditionData.regionArea = "";
      }
      let formData = {
        ...v,
        ...this.conditionData,
        current: pageNum,
        size: pageSize,
        fastLike: v.keyword,
        createTimeBegin: v.createTimeBegin
          ? v.createTimeBegin + " 00:00:00"
          : "",
        createTimeEnd: v.createTimeEnd ? v.createTimeEnd + " 23:59:59" : "",
      };
      this.oldFormData = JSON.parse(JSON.stringify(formData));
      const { code, data } = await customerPage(formData);
      if (code === 200) {
        this.claimRecordList = data.records;
        this.totalNum = data.total;
      }
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
    // 删除
    deleteItem(e) {
      if (e && e.length) {
        this.$confirm("删除改用户后相关数据会丢失，确认删除？", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let ids = e.map((val) => val.userId).toString();
            customerDelete({
              ids,
            })
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.changeList();
              })
              .catch((err) => {
                this.$message({
                  type: "error",
                  message: "失败!",
                });
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else {
        this.$message.error("请选择需要删除的角色！");
      }
    },
    // 编辑
    editItem(e) {
      this.editData = e;
      this.operateTitle = "编辑用户-" + e.account;
      this.isAddVisible = true;
    },
    handleAddUser() {
      this.editData = {};
      this.operateTitle = "添加用户";
      this.isAddVisible = true;
    },
    // 跳页面
    handleGoPath(path, item) {
      let ids = [];
      let name = "";
      let tenantId = "";
      if (item) {
        ids = item.userId;
        name = item.roleName;
        tenantId = item.tenantId;
      } else if (
        this.$refs.GeneralTable.tableSelectionList &&
        this.$refs.GeneralTable.tableSelectionList.length
      ) {
        ids = this.$refs.GeneralTable.tableSelectionList
          .map((val) => val.userId)
          .toString();
        name = this.$refs.GeneralTable.tableSelectionList
          .map((val) => val.roleName)
          .toString();
      } else {
        this.$message.warning("请选择需要设置的角色！");
        return false;
      }
      this.$router.push({
        path: `${path}/${ids}/${name}/${tenantId}`,
      });
    },
    exportData(e) {
      // if (!e || !e.length) {
      //   this.$message.warning('请选择需要导出的角色！');
      //   return false;
      // }
      customerExportExcel({
        ...this.oldFormData,
        ids: e.map((val) => val.userId),
      });
    },
    async handleAssociation(item) {
      this.editData = item;
      let userIds = await listGrantedUser({ roleId: item.id });
      let treeData = await getRelateTreeData({
        tenantId: item.tenantId,
      });
      if (treeData.data && treeData.data.deptVOS) {
        this.relateTree = treeData.data.deptVOS;
      }
      if (userIds.data && userIds.code == 200) {
        this.treeSelectKeyList = userIds.data.map((val) => {
          return {
            title: val.name,
            id: val.id,
          };
        });
      }
      this.topPickerData = [{ label: item.roleName, value: 0 }];
      this.showTreeDialog = true;
      //   .then(res => {
      //   console.log(res);
      //   res.data && res.data.deptVOS ? this.relateTree = res.data.deptVOS : '';
      //   res.data && res.data.userIds ? this.treeSelectNodeList = res.data.userIds : '';
      // });
    },
    submitRelateUser(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      roleBindUser({
        roleId: this.editData.id,
        userIds: e.map((val) => val.id),
      })
        .then((res) => {
          this.changeList();
          loading.close();
        })
        .catch((err) => {
          loading.close();
          console.log(err);
        });
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
    onSelectedSingle(e, type) {
      if (type == "regionProvince" && e.value == "省") {
        this.conditionData.regionCity = "";
        this.conditionData.regionArea = "";
      } else if (type == "regionCity" && e.value == "市") {
        this.conditionData.regionArea = "";
      }
      this.conditionData[type] = e.value.length > 1 ? e.value : "";
    },
    handleAllocationCustomer() {
      this.customerIds = this.$refs.GeneralTable.tableSelectionList.map(
        (val) => val.userId
      );
      this.referrerIds = this.$refs.GeneralTable.tableSelectionList.map(
        (val) => val.agentId
      );
      if (this.referrerIds.length) {
        this.$message.error("客户已绑定代理人，请逐个修改");
      } else if (this.customerIds.length) {
        this.isAllocationCustomer = true;
      } else {
        this.$message.error("请选择用户");
      }
    },
    handleBindProject() {
      this.customerIds = this.$refs.GeneralTable.tableSelectionList.map(
        (val) => val
      );
      if (this.customerIds.length && this.customerIds.length === 1) {
        this.bindProjectOpen = true;
      } else if (this.customerIds.length && this.customerIds.length > 1) {
        this.$message.warning("只能操作一个用户！");
      } else {
        this.$message.error("请选择用户");
      }
    },
    bindProject(e) {
      bindUserProject({
        bindUserId: this.customerIds[0].userId || "",
        ...e,
      }).then(
        (res) => {
          this.bindProjectOpen = false;
          this.$message.success("绑定成功！");
        },
        (err) => {
          this.$message.error("绑定失败！");
          this.bindProjectOpen = false;
        }
      );
    },
    handleSetRuleForm(e) {
      console.log(e);
      customerBindReferrer({
        ...e,
        userIds: this.customerIds,
      }).then((res) => {
        this.changeList();
      });
    },
    handleResetPassword(e) {
      this.isByUserVisible = true;
      this.editData = e;
      this.password = "";
    },
    handleUpdateUser() {
      if (
        !validatePassword(this.password)
      ) {
        this.$message.error("请填写正确最小长度为8位，密码由数字、字母和特殊字符组合！");
        return false;
      }
      customerResetPwd({
        userId: this.editData.userId,
        password: this.password,
      })
        .then((res) => {
          if (res.data) {
            this.$message.success("重置成功");
            this.isByUserVisible = false;
          } else {
            this.$message.error("重置失败");
          }
        })
        .catch((err) => {
          this.$message.error("重置失败");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.reset-password {
  display: flex;
  align-items: center;
}
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px;
  .policy-form-box {
    display: flex;
    background: #ffffff;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
  }
  .policy-table {
    flex: 1;
    margin-top: 12px;
    background: #ffffff;
    padding: 12px;
    border-radius: 8px;
    .polecy-button + .polecy-button {
      margin: 0;
    }
    .polecy-button {
      position: relative;
      padding: 12px;
    }
    .polecy-button::after {
      content: "";
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background-color: #cccccc;
    }
    .polecy-button-no::after {
      display: none;
    }
    .table-button {
      display: flex;
      justify-content: space-between;
      margin: 12px;
      margin-bottom: 0;
      padding-bottom: 12px;
      border-bottom: 1px dashed #cccccc;
      &-right {
        display: flex;
      }
    }
  }
}
</style>
<style lang="scss">
.container {
  padding: 12px;
  .policy-form-box {
    .el-form-item__content {
      width: 220px !important;
    }
    .el-form {
      flex: 1;
    }
  }
  .el-table {
    margin: 12px;
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    border-radius: 8px;
    overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-button--primary {
    background-color: #4278c9;
    border-color: #4278c9;
  }
  .el-button--primary.is-plain {
    color: #4278c9;
    background: rgba(66, 120, 201, 0.1);
    border-color: #4278c9;
  }
  .el-button--success {
    background-color: #00bc0d;
    border-color: #00bc0d;
  }
  .el-button--danger {
    background-color: #c94242;
    border-color: #c94242;
  }
}
</style>
